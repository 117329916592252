/* eslint-disable no-console */
/* eslint-disable import/no-unresolved */
import { ComponentPropsWithoutRef, ReactElement } from 'react';
import { initApolloClient } from 'lib/apollo/withApollo';
import { withBanner } from 'lib/withBanner';
import { CentralHome } from 'components/organisms';
import RecordFlattener from 'util/cmsRecordFlattener';
import {
  GET_HOME_COMPONENTS,
  BANNER_QUERY,
  GET_FAQ_ARTICLES,
  GET_SEO_FAQ_ARTICLES,
} from 'graphql/queries';
import { Variant, Experiment } from '@olxbr/react-experimentation';
import { Query } from 'graphql/types';
import { useLoggedUser } from 'hooks';
import { counterMetric } from 'clients/metrics';
import { withMetaTags } from 'lib/seo';
import BridgeJS from 'util/bridgeWebView';
import NextLink from 'next/link';
import { Link } from '@olxbr/ds-web';
import FeedbackFooter from 'components/experiments/PendingPublishAds/FeedbackFooter';
import ShowPendingAdsExperiment from 'components/experiments/PendingPublishAds/ShowPendingAdsExperiment';
import ContactFooter from 'components/experiments/PendingPublishAds/ContactFooter';
import handleClick from 'util/handleClick';
import styled from 'styled-components';

type Props = {
  categories: [any];
  faqs: [any];
};

const ArticleLink = styled(Link)`
  width: 100%;
  justify-content: left;
  margin-top: var(--spacing-stack-xxxs);
  margin-bottom: var(--spacing-stack-xxs);
`;

const flattener = new RecordFlattener();

export const Index = ({ categories, faqs }: Props): ReactElement => {
  const {
    user,
    loading: loadingUser,
    error: errorLoadingUser,
  } = useLoggedUser();
  const serializedCategories = flattener.flatten(categories);
  const serializedFaqs = flattener.flatten(faqs);

  const bridge = BridgeJS.getInstance();

  const Central = () => (
    <CentralHome
      categories={serializedCategories}
      faqs={serializedFaqs}
      bridge={bridge}
      user={user}
    />
  );

  const ExperimentVariant = (
    props: Pick<
      ComponentPropsWithoutRef<typeof ShowPendingAdsExperiment>,
      'Footer' | 'listFooterLink' | 'trackingViewLabel'
    >,
  ) => (
    <ShowPendingAdsExperiment
      user={user}
      bridge={bridge}
      loadingUser={loadingUser}
      errorLoadingUser={errorLoadingUser}
      Fallback={<Central />}
      {...props}
    />
  );

  const VariantWithContactFormLink = () => (
    <ExperimentVariant
      trackingViewLabel="ad_aguardando_publicacao"
      Footer={ContactFooter}
    />
  );

  const VariantWithFeedBackButtons = () => (
    <ExperimentVariant
      trackingViewLabel="ajuda_foi_util"
      Footer={FeedbackFooter}
      listFooterLink={
        <NextLink href="s/article/anuncio-aguardando-publicacao" passHref>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <ArticleLink
            size="small"
            onClick={(event: any) =>
              handleClick(event, 'artigo', 'self_service_screen', {
                article_slug: 'anuncio-aguardando-publicacao',
              })
            }
          >
            Artigo sobre anúncio aguardando publicação
          </ArticleLink>
        </NextLink>
      }
    />
  );

  return (
    <>
      <Experiment name="tela-autosservico-ad-aguardando-publicacao-menu-hamburger">
        <Variant value="control" default>
          <Central />
        </Variant>

        <Variant value="A">
          <VariantWithContactFormLink />
        </Variant>

        <Variant value="B">
          <VariantWithFeedBackButtons />
        </Variant>
      </Experiment>
    </>
  );
};

export const getServerSideProps = async (ctx: any) => {
  const apolloClient = initApolloClient();
  counterMetric.increment(
    { name: 'home', category: 'request', level: 'invoke' },
    1,
  );

  let categories = null;
  try {
    const { data } = await apolloClient.query<Query>({
      query: GET_HOME_COMPONENTS,
    });

    categories = data.categories;
  } catch (e) {
    const { message } = e as Error;
    console.error(`Error in GET_HOME_COMPONENTS: ${message}`);
    counterMetric.increment(
      { name: 'GET_HOME_COMPONENTS', category: 'query', level: 'error' },
      1,
    );
    ctx.res.status(500);
    return { props: { errorCode: 500 } };
  }

  let bannerData = null;
  try {
    const { data } = await apolloClient.query<Query>({ query: BANNER_QUERY });
    bannerData = flattener.flatten(data.banners);
  } catch (e) {
    const { message } = e as Error;
    console.error(`Error in BANNER_QUERY: ${message}`);
    counterMetric.increment(
      { name: 'BANNER_QUERY', category: 'query', level: 'error' },
      1,
    );

    ctx.res.status(500);
    return { props: { errorCode: 500 } };
  }

  try {
    await apolloClient.query<Query>({ query: GET_FAQ_ARTICLES });
  } catch (e) {
    const { message } = e as Error;
    console.error(`Error in GET_FAQ_ARTICLES: ${message}`);
    counterMetric.increment(
      { name: 'GET_FAQ_ARTICLES', category: 'query', level: 'error' },
      1,
    );
    ctx.res.status(500);
    return { props: { errorCode: 500 } };
  }

  let seoFaqs = null;
  try {
    const { data } = await apolloClient.query<Query>({
      query: GET_SEO_FAQ_ARTICLES,
    });

    seoFaqs = flattener.flatten(data.faqs);
  } catch (e) {
    const { message } = e as Error;
    console.error(`Error in GET_SEO_FAQ_ARTICLES: ${message}`);
    counterMetric.increment(
      { name: 'GET_SEO_FAQ_ARTICLES', category: 'query', level: 'error' },
      1,
    );
  }

  counterMetric.increment(
    { name: 'home', category: 'request', level: 'success' },
    1,
  );

  return {
    props: {
      banner: bannerData.length ? bannerData : null,
      categories,
      faqs: seoFaqs,
      initialApolloState: apolloClient.cache.extract(),
    },
  };
};

export default withMetaTags(withBanner(Index));
