import styled from 'styled-components';

import { getCountdownForAds } from 'util/moment';

import { ReactComponent as AlertSVG } from 'public/images/ico-clock.svg';

type Props = {
  date: String;
};

const Span = styled.span`
  height: 24px;
  color: #f9af27;
  font-family: 'Nunito Sans';
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  margin-left: 8px;
  margin-top: 1px;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

const Div = styled.div`
  display: flex;
  align-items: center;
`;

const SVG = styled(AlertSVG)`
  vertical-align: middle;
`;

const AdPublishingCounter = (props: Props) => {
  const { date } = props;

  return (
    <Div>
      <SVG />
      <Span>
        {`Em até ${getCountdownForAds(date)}h seu anúncio será publicado`}
      </Span>
    </Div>
  );
};

export default AdPublishingCounter;
