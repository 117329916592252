import { ElementType, ReactElement, useEffect } from 'react';
import { pageView } from 'tracking/lurker';
import { Text, Alertbox } from '@olxbr/ds-web';
import { Ad } from 'typings/ads';
import { IntersectAtEndObserver } from '@olxbr/olx-cjs-component-lib';
import PendingAd from './PendingAd';
import {
  ShowFastResolutionContainer,
  Loading,
  ListContainer,
} from './PendingAdsList.styles';

type PendingAdsListProps = {
  loading: boolean;
  ads: Ad[];
  fetchAds: () => void;
  goToHome: () => void;
  Footer: any;
  listFooterLink?: ReactElement;
  trackingViewLabel?: string;
};

const PendingAdsList = ({
  loading,
  ads,
  fetchAds,
  goToHome,
  Footer,
  listFooterLink,
  trackingViewLabel,
}: PendingAdsListProps) => {
  useEffect(() => {
    pageView('self_service_screen', trackingViewLabel);
  }, []);

  return (
    <>
      <ShowFastResolutionContainer>
        <Text.TitleLarge marginBottom="--spacing-stack-xxxs">
          Anúncio no prazo para ser publicado
        </Text.TitleLarge>

        <Alertbox title="Os anúncios podem levar até 24h para serem publicados" />

        <IntersectAtEndObserver
          isLoading={loading}
          handleIntersection={fetchAds}
          LoadingComponent={
            <Loading size="extra-small" aria-label="carregando mais anúncios" />
          }
        >
          <ListContainer>
            {ads.map((ad) => (
              <PendingAd key={ad.id} ad={ad} />
            ))}
          </ListContainer>
        </IntersectAtEndObserver>

        {listFooterLink}
      </ShowFastResolutionContainer>

      <Footer goToHome={goToHome} />
    </>
  );
};

PendingAdsList.defaultProps = {
  listFooterLink: null,
  trackingViewLabel: '',
};

export default PendingAdsList;
