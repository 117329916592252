/* eslint-disable react/jsx-props-no-spreading */
import Head from 'next/head';

const DESCRIPTION =
  'Está precisando de ajuda? Na Central de Ajuda da OLX você encontra respostas para suas dúvidas e a possibilidade de resolver seus problemas sem precisar entrar em contato conosco. Não encontrou o que busca? Falar com a gente é bem simples, basta clicar em "Fale Conosco", nosso time de atendimento vai ajudar a resolver seu problema.';

const withMetaTags = (PageComponent: any, description = DESCRIPTION) => {
  const WithMetaTags = (pageProps: any) => (
    <>
      <Head>
        <meta name="description" content={description} />
      </Head>
      <PageComponent {...pageProps} />
    </>
  );
  return WithMetaTags;
};

export default withMetaTags;
