import { Button, Text } from '@olxbr/ds-web';
import Link from 'next/link';
import { clickLink } from 'tracking/lurker';
import handleClick from 'util/handleClick';
import Icons from '@olxbr/olx-icons';
import BottomFooter from '../BottomFooter';

type ContactFooterProps = {
  goToHome: () => void;
};

const ContactFooter = ({ goToHome }: ContactFooterProps) => {
  const handleClickGoToHome = () => {
    clickLink('link_help_center', 'self_service_screen');
    goToHome();
  };

  const handleClickGoToForm = (event: any) => {
    window.sessionStorage.setItem(
      'service',
      JSON.stringify({
        name: 'meu-anuncio-esta-aguardando-publicacao',
        externalId: 'anuncio_aguardando_publicacao',
      }),
    );

    handleClick(event, 'fale_conosco', 'self_service_screen');
  };

  return (
    <BottomFooter>
      <Text.BodySmall weight="bold">Não fique com dúvidas</Text.BodySmall>
      <Link
        href="https://ajuda.olx.com.br/formulario-de-contato?service=meu-anuncio-esta-aguardando-publicacao"
        passHref
      >
        <Button
          icon={<Icons.System.Mail />}
          variant="secondary"
          size="small"
          onClick={handleClickGoToForm}
        >
          Fale com a gente
        </Button>
      </Link>
      <Button variant="link-button" size="small" onClick={handleClickGoToHome}>
        Quero falar sobre outro assunto
      </Button>
    </BottomFooter>
  );
};

export default ContactFooter;
