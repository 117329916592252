import styled from 'styled-components';

export type FooterContentProps = {
  footerSize?: string,
}
export const Footer = styled.div<FooterContentProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-row-gap: var(--spacing-stack-xxxs);
  background-color: var(--color-neutral-lighter);
  padding: var(--spacing-inset-lg);
  position: fixed;
  width: 100%;
  height: ${({ footerSize }) => (footerSize || '164px')};
  bottom: 0;
`;

export const FooterWrapper = styled.footer<FooterContentProps>`
  padding-bottom: ${({ footerSize }) => (footerSize || '164px')};
`;
