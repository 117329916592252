import { useContext } from 'react';
import { BannerContext } from 'components/context';

export const withBanner = (PageComponent: any) => {
  const WithBanner = ({ banner, ...pageProps }: { banner?: string }) => {
    const { setBanners } = useContext(BannerContext);
    setBanners(banner);

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <PageComponent {...pageProps} />
    );
  };

  WithBanner.defaultProps = {
    banner: null,
  };

  return WithBanner;
};
