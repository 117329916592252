import apigw from 'lib/apigw';
import { AxiosResponse } from 'axios';
import { Ad } from 'typings/ads';

const API_PENDING_ADS = 'mydata/v1/data?status=pending_publish';

const getPendingAds = (
  page: number,
  pageSize: number,
): Promise<AxiosResponse<{ ads: Ad[], total: number }>> => apigw.get(
  `${API_PENDING_ADS}&page=${page}&page_size=${pageSize}`,
);

export default getPendingAds;
