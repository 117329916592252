import moment from 'moment';

export const getCountDown = (date, time = 'seconds') => {
  const endDate = moment(date, 'YYYY-MM-DD HH:mm:ss').subtract(3, 'h');
  moment.locale('pt-br');

  return endDate.diff(moment(), time);
};

export const getCountdownForAds = (date) => {
  const endDate = moment(date, 'YYYY-MM-DD HH:mm:ss').add(24, 'h');
  moment.locale('pt-br');
  return moment.duration(endDate.diff(moment())).hours();
};

export const formatDate = (date) => {
  const data = moment(date, 'YYYY-MM-DD HH:mm:ss');
  moment.locale('pt-br');

  return `${data.format('L')} às ${data.format('LT')}`;
};
export const formatDateWithoutHours = (date) => {
  const data = moment(date, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
  moment.locale('pt-br');

  return `${data.format('DD/MM/YYYY')}`;
};

export const formatDateGMT = (date) => {
  const data = moment(date, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
  moment.locale('pt-br');

  return `${data.format('DD/MM/YYYY')} às ${data.format('HH:mm')}`;
};

export const validateDate = (value) => {
  const now = moment().startOf('day');
  const date = moment(value, 'DD/MM/YYYY');

  if (!date.isValid()) {
    return false;
  }

  return now.diff(date) >= 0;
};
