import { ReactChild } from 'react';
import { FooterWrapper, Footer } from './BottomFooter.styles';

export type BottomFooterProps = {
  children: ReactChild | ReactChild[];
  footerSize?: string;
};

const BottomFooter = ({ children, footerSize }: BottomFooterProps) => (
  <FooterWrapper footerSize={footerSize}>
    <Footer footerSize={footerSize}>{children}</Footer>
  </FooterWrapper>
);

export default BottomFooter;
