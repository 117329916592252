import styled from 'styled-components';

export const PendingAdImageContainer = styled.figure`
  display: block;
  max-width: 96px;
  width: 100%;
  height: 72px;
  background: var(--color-neutral-medium);
  border-radius: var(--border-radius-sm);
  overflow: hidden;
`;

export const Image = styled.img`
  height: 100%;
  margin: auto;
`;

export const PendingAdContainer = styled.li`
  &:not(:last-child) {
    margin-bottom: var(--spacing-stack-xxs);
    border-bottom: 1px solid var(--color-neutral-medium);
  }
  padding-bottom: var(--spacing-stack-xxs);
`;
