import AdInfoContainer from 'components/atoms/ad-info-container';
import AdPublishingCounter from 'components/atoms/ad-publishing-counter';
import { Text, Flex } from '@olxbr/ds-web';
import { formatDate } from 'util/moment';
import { Ad } from 'typings/ads';
import {
  Image,
  PendingAdContainer,
  PendingAdImageContainer,
} from './PendingAd.styles';

type PendingAdProps = { ad: Ad };

const PendingAd = ({ ad: { url, title, price, date } }: PendingAdProps) => (
  <PendingAdContainer>
    <AdPublishingCounter date={date} />
    <Flex marginBottom="--spacing-stack-xxxs">
      <PendingAdImageContainer>
        <Image src={url} alt="Image destacada do anúncio" />
      </PendingAdImageContainer>
      <AdInfoContainer title={title} price={price} />
    </Flex>

    <Text.BodySmall weight="regular" color="--color-neutral-darker">
      {`${formatDate(date)}`}
    </Text.BodySmall>
  </PendingAdContainer>
);

export default PendingAd;
