import { Button, Flex, Text } from '@olxbr/ds-web';
import { useState } from 'react';
import styled from 'styled-components';
import Icons from '@olxbr/olx-icons';
import { clickLink } from 'tracking/lurker';
import BottomFooter from '../BottomFooter';
import ContactFooter from '../ContactFooter';

const GreenText = styled.span`
  color: var(--color-feedback-success-light);
`;

const SuccessFeedBackMessage = () => (
  <Text.BodySmall>
    Agradecemos o seu feedback!{' '}
    <GreenText color="--color-feedback-success-light">=)</GreenText>
  </Text.BodySmall>
);

type FeedbackFooterProps = {
  goToHome: () => void;
};

const FeedbackFooter = ({ goToHome }: FeedbackFooterProps) => {
  const [successFeedback, setSuccessFeedback] = useState(false);
  const [showContactFooter, setShowContactFooter] = useState(false);

  const handleClickNot = () => {
    clickLink('ajuda_foi_util_nao', 'self_service_screen');

    setShowContactFooter(true);
  };

  const handleClickYes = () => {
    clickLink('ajuda_foi_util_sim', 'self_service_screen');

    setSuccessFeedback(true);
  };

  const FeedbackActions = () => (
    <Flex>
      <Button
        icon={<Icons.System.ThumbsUp />}
        variant="secondary"
        marginRight="--spacing-stack-xxxs"
        size="small"
        onClick={handleClickYes}
      >
        Sim
      </Button>

      <Button
        icon={<Icons.System.ThumbsDown />}
        variant="secondary"
        size="small"
        onClick={handleClickNot}
      >
        Não
      </Button>
    </Flex>
  );

  if (showContactFooter) return <ContactFooter goToHome={goToHome} />;

  return (
    <BottomFooter footerSize="116px">
      <Text.BodySmall weight="bold">Essa ajuda foi útil?</Text.BodySmall>
      {successFeedback ? <SuccessFeedBackMessage /> : <FeedbackActions />}
    </BottomFooter>
  );
};

export default FeedbackFooter;
