import styled from 'styled-components';
import { Spinner } from '@olxbr/ds-web';

export const ShowFastResolutionContainer = styled.section`
  padding: var(--spacing-inset-lg);
  margin: auto;
  width: 100%;

  @media (min-width: 600px) {
    max-width: 530px;
  }

  @media (min-width: 960px) {
    max-width: 900px;
  }
`;

export const ListContainer = styled.ul`
  list-style: none;
  margin: var(--spacing-stack-xxs) 0 0;
`;

export const Loading = styled(Spinner)`
  margin: auto;
`;
