import {
  ElementType,
  ReactChild,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { User } from 'typings/user';
import BridgeJS from 'util/bridgeWebView';
import { Spinner } from '@olxbr/ds-web';
import getPendingAds from 'api/getPendingAds';
import reducer, {
  ActionsTypes,
} from 'reducers/selfservices/pending-publishing-home';
import PendingAdsExperiment from '../PendingAdsList';

type ShowPendingAdsExperimentProps = {
  Fallback: ReactElement;
  user: null | User;
  bridge: BridgeJS;
  loadingUser: boolean;
  errorLoadingUser: any;
  Footer: ElementType;
  listFooterLink?: ReactElement;
  trackingViewLabel: string;
};

const PAGE_SIZE = 3;

// This is used because of the delay to load ds-web/tokens styles in SSR
const LoadingContainer = ({ children }: { children: ReactChild }) => (
  <div
    style={{
      width: 32,
      height: 32,
      color: 'rgb(110, 10, 214)',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      position: 'fixed',
    }}
  >
    {children}
  </div>
);

const getNextPageNumber = (adsLenght?: number, totalAds?: number) => {
  if (!adsLenght || !totalAds) return 1;

  return Math.ceil(adsLenght / PAGE_SIZE) + 1;
};

export const useFetchAds =
  (
    state: Parameters<typeof reducer>[0],
    dispatch: (action: Parameters<typeof reducer>[1]) => void,
  ) =>
  () => {
    const isLastPage = state?.ads?.length === state?.total;

    if (state.loading || isLastPage) {
      return;
    }

    const nextPageNumber = getNextPageNumber(state?.ads?.length, state.total);
    dispatch({ type: ActionsTypes.FETCH_ADS });

    getPendingAds(nextPageNumber, PAGE_SIZE)
      .then((response) => {
        const { data } = response;
        if (data?.ads.length && data?.total) {
          return dispatch({
            type: ActionsTypes.RESOLVE_ADS,
            ads: data.ads,
            total: data.total,
          });
        }

        throw new Error('empty ads list');
      })
      .catch((error) => {
        dispatch({ type: ActionsTypes.ERROR, error });
      });
  };

const initialState = {
  loading: false,
  fetch: false,
  error: null,
  ads: undefined,
  total: 0,
};

const ShowPendingAdsExperiment = ({
  Fallback,
  user,
  loadingUser,
  bridge,
  errorLoadingUser,
  Footer,
  listFooterLink,
  trackingViewLabel,
}: ShowPendingAdsExperimentProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showHome, setShowHome] = useState(false);

  const isAndroidWebView = useMemo(
    () => bridge.isWebView() && bridge.getPlatform() === 'android',
    [bridge],
  );

  const fetchAds = useCallback(useFetchAds(state, dispatch), [state]);

  useEffect(() => {
    if (showHome || !isAndroidWebView || loadingUser) return;

    if (errorLoadingUser) {
      dispatch({ type: ActionsTypes.ERROR, error: 'error getting user' });
      return;
    }

    if (user) {
      fetchAds();
    }
  }, [user, loadingUser, errorLoadingUser]);

  if (showHome) return Fallback;

  if (!isAndroidWebView || state.error) {
    return Fallback;
  }

  if (!state.fetch) {
    return (
      <LoadingContainer>
        <Spinner aria-label="carregando dados" />
      </LoadingContainer>
    );
  }

  if (state?.ads?.length) {
    return (
      <PendingAdsExperiment
        loading={!!state.loading}
        ads={state.ads}
        fetchAds={fetchAds}
        goToHome={() => setShowHome(true)}
        Footer={Footer}
        listFooterLink={listFooterLink}
        trackingViewLabel={trackingViewLabel}
      />
    );
  }

  return Fallback;
};

export default ShowPendingAdsExperiment;
