import styled from 'styled-components';

import { formatDate } from 'util/moment';

type Props = {
  title: string;
  price: string;
  insertedAt?: string;
};

const Title = styled.span`
  height: 24px;
  color: #4a4a4a;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Price = styled.span`
  height: 20px;
  color: #4a4a4a;
  font-family: 'Nunito Sans';
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 8px;
  margin-top: 4px;
`;

const Label = styled.label`
  height: 16px;
  width: 67px;
  color: #4a4a4a;
  font-family: 'Nunito Sans';
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  margin-top: 8px;
  margin-bottom: 2px;
`;

const DivDate = styled.div`
  height: 14px;
  width: 131px;
  border-radius: 4px;
  background-color: #f6f6f6;
  padding: 6px 10px;
  line-height: 14px;
  text-align: center;
`;

const Span = styled.span`
  height: 14px;
  width: 101px;
  color: #4a4a4a;
  font-family: 'Nunito Sans';
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  padding: 4px 8px 6px;
`;

const AdInfoContainer = (props: Props) => {
  const { title, price, insertedAt } = props;

  return (
    <>
      <Title>{title}</Title>
      <Price>
        {`R$
          ${price ? parseFloat(price).toFixed(2).replace('.', ',') : '-'}`}
      </Price>
      {insertedAt && (
        <>
          <Label>Enviado em:</Label>
          <DivDate>
            <Span>{`${formatDate(insertedAt)}`}</Span>
          </DivDate>
        </>
      )}
    </>
  );
};

export default AdInfoContainer;
