import { Ad } from 'typings/ads';

export enum ActionsTypes {
  FETCH_ADS,
  RESOLVE_ADS,
  ERROR,
}

type Action = {
  type: ActionsTypes;
  ads?: Ad[];
  error?: any;
  total?: number;
};

type State = {
  loading: boolean;
  error: any;
  ads?: Ad[];
  fetch: boolean;
  total: number;
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionsTypes.ERROR: {
      return {
        ...state,
        loading: false,
        fetch: true,
        error: action.error,
      };
    }
    case ActionsTypes.FETCH_ADS:
      return {
        ...state,
        loading: true,
      };
    case ActionsTypes.RESOLVE_ADS: {
      return {
        ...state,
        loading: false,
        fetch: true,
        ads: [...(state.ads || []), ...(action.ads || [])],
        total: action.total || 0,
      };
    }
    default:
      return state;
  }
};

export default reducer;
